<template>
    <v-sheet elevation="2" width="100%">

        <v-toolbar short dense color="primary" dark flat>
            <v-toolbar-title>Registro transfusional</v-toolbar-title>

            <v-progress-linear
                v-if="loadingDatos"
                :active="loadingDatos"
                :indeterminate="loadingDatos"
                absolute
                bottom
                color="primary"
            ></v-progress-linear>

            <v-spacer></v-spacer>

            <v-btn icon @click="cargaTransfusionesTodas" :disabled="muestraSol">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>


            <v-btn  rounded dark @click="dialog=true" :disabled="muestraSol" color="blue">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>

        <v-simple-table>
            <template v-slot:default>

                <thead>
                    <tr>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Detalles</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Componente a transfundir</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Volumen a transfundir</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Inicio</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Termino</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Volumen transfundido</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Estado general/Observaciones</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Acciones</span></th>
                    </tr>
                </thead>

                <tbody>

                    <tr class="mb-3" :class="turnoColor(item.turno)+'--text'" v-for="item in datosTable" :key="item.id">

                        <td width="20%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-left">
                                <b>Motivo:  </b>{{item.motivoTransfusion}}<br/>
                                <b>Grupo y Rh de la unidad:  </b>{{item.grupoRhUnidad}}<br/>
                                <b>Creada por:  </b>{{item.creado}}<br/>
                            </div>
                        </td>

                        <td width="15%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-center">
                                <p>{{item.componenteaTransfundir}}</p>
                            </div>
                        </td>

                        <td width="5%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-center">
                                <p>{{item.volumenaTransfundir}} ml</p>
                            </div>
                        </td>

                        <td width="10%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-center">
                                <p>{{item.fechaTransfusion}}</p>
                            </div>
                        </td>

                        <td width="10%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-center">
                                <p>{{item.fechaTermino}}</p>
                            </div>
                        </td>

                        <td width="5%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-center" v-if="item.volumenTransfundido">
                                <p>{{item.volumenTransfundido}} ml</p>
                            </div>
                        </td>

                        <td width="25%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-left" >
                                <div v-if="item.observaciones" >
                                    <p class="pre-formatted" >{{item.observaciones}}</p><br/>
                            </div>
                            </div>
                        </td>

                        <td width="10%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon @click="abreCancela(item)" v-if="!item.terminada" v-on="on">mdi-check</v-icon>
                                    </template>
                                    <span>Terminar</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon @click="abreSignosVitales(item)" v-if="!item.terminada" v-on="on">mdi-chart-line</v-icon>
                                    </template>
                                    <span>Registrar signos</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon @click="abreObservacion(item)" v-if="!item.terminada" v-on="on">mdi-eye</v-icon>
                                    </template>
                                    <span>Registrar Observacion</span>
                                </v-tooltip>
                            </div>
                        </td>
                        
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <!--
        Dialog AGREGA
        -->
        <v-dialog
            v-model="dialog"
            max-width="1000"
            persistent
        >
            <v-form
            ref="solForm"
            @submit="agrega"
            onSubmit="return false;"
            v-model="validaSol"
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Agregar nueva transfusion
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        class="mt-3"
                        v-model="datosTransfusionForm.motivoTransfusion"
                        :rules="[
                            value => !!value || 'Requerido.',
                        ]"
                        rounded
                        outlined
                        label="Motivo de transfusion"
                        dense
                    ></v-text-field>
                    <page-fecha
                        label='Fecha de la transfusion'
                        :model.sync="datosTransfusionForm.fechaTransfusion"
                        :dense="true"
                        :outlined="true"
                        :rules="[v => !!v || 'Requerido.']"
                    ></page-fecha>
                    <v-text-field
                        class="mt-3"
                        v-model="datosTransfusionForm.numeroUnidad"
                        :rules="[
                            value => !!value || 'Requerido.',
                            value => value >= 0.01 || 'Valor MENOR al mínimo (0.01)',
                            value => value <= 10000 || 'Valor MAYOR al máximo (10,000)'
                        ]"
                        type="number"
                        rounded
                        outlined
                        label="Numero de unidad"
                        dense
                    ></v-text-field>
                    <v-text-field
                        class="mt-3"
                        v-model="datosTransfusionForm.tipoUnidad"
                        :rules="[
                            value => !!value || 'Requerido.',
                        ]"
                        rounded
                        outlined
                        label="Tipo de unidad"
                        dense
                    ></v-text-field>
                            <v-menu
                                ref="menu"
                                v-model="horaInicio"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="datosTransfusionForm.horaInicio"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="datosTransfusionForm.horaInicio"
                                    label="Hora de inicio"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                v-if="horaInicio"
                                format="24hr"
                                v-model="datosTransfusionForm.horaInicio"
                                full-width
                                @click:minute="$refs.menu.save(datosTransfusionForm.horaInicio)"
                                ></v-time-picker>
                            </v-menu>
                    <v-expand-transition>
                        <div v-if=true no-gutters>
                            <v-sheet
                                elevation="2"
                                class="mb-2"
                            >
                                <v-toolbar class="headline grey lighten-2 mb-2" dense flat>
                                    <v-toolbar-title>
                                        Doble Verificacion
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-row no-gutters>
                                    <v-col
                                        class="px-1"
                                        xs="6"
                                        sm="6"
                                        md="4"
                                        lg="3"
                                        xl="4"
                                        cols="12"
                                    >
                                        <v-checkbox
                                            class="mt-5 mb-2"
                                            label="Identidad del paciente"
                                            v-model="datosTransfusionForm.identidadPaciente"
                                        />
                                        <v-select
                                            class="mt-3"
                                            v-model="datosTransfusionForm.componenteaTransfundir"
                                            :items="componentesTransfusion"
                                            :rules="[value => !!value || 'Requerido.']"
                                            label="Componente a transfundir"
                                            rounded
                                            outlined
                                            dense
                                            item-text="Componente a transfundir"
                                        >
                                        </v-select>
                                        <v-checkbox
                                            class="mt-3"
                                            label="Pruebas de compatibilidad"
                                            v-model="datosTransfusionForm.pruebasCompatibilidad"
                                        />
                                        <v-checkbox
                                            class="mt-3"
                                            label="Consentimiento informado"
                                            v-model="datosTransfusionForm.consentimientoInformado"
                                        />
                                    </v-col>
                                    <v-col
                                        class="px-1"
                                        xs="6"
                                        sm="6"
                                        md="4"
                                        lg="3"
                                        xl="4"
                                        cols="12"
                                    >
                                        <v-select
                                            class="mt-3"
                                            v-model="datosTransfusionForm.grupoRhPaciente"
                                            :items="gruposRh"
                                            :rules="[value => !!value || 'Requerido.']"
                                            label="Grupo y Rh del paciente"
                                            rounded
                                            outlined
                                            dense
                                            item-text="Grupo y Rh del paciente"
                                        >
                                        </v-select>
                                        <v-text-field
                                            v-model="datosTransfusionForm.volumenaTransfundir"
                                            :rules="[
                                                value => !!value || 'Requerido.',
                                                value => value >= 0.01 || 'Valor MENOR al mínimo (0.01)',
                                                value => value <= 10000 || 'Valor MAYOR al máximo (10,000)'
                                            ]"
                                            type="number"
                                            rounded
                                            outlined
                                            label="Volumen a transfundir (ml)"
                                            dense
                                        ></v-text-field>
                                        <v-checkbox
                                            class="mt-3"
                                            label="Antecedentes de alergias"
                                            v-model="datosTransfusionForm.antecedentesAlergias"
                                        />
                                        <v-checkbox
                                            class="mt-3"
                                            label="Indicacion escrita"
                                            v-model="datosTransfusionForm.indicacionEscrita"
                                        />
                                    </v-col>
                                    <v-col
                                        class="px-1"
                                        xs="6"
                                        sm="6"
                                        md="4"
                                        lg="3"
                                        xl="4"
                                        cols="12"
                                    >
                                    <v-select
                                            class="mt-3"
                                            v-model="datosTransfusionForm.grupoRhUnidad"
                                            :items="gruposRh"
                                            :rules="[value => !!value || 'Requerido.']"
                                            label="Grupo y Rh de la unidad"
                                            rounded
                                            outlined
                                            dense
                                            item-text="Grupo y Rh de la unidad"
                                        >
                                        </v-select>
                                        <v-text-field
                                            v-model="datosTransfusionForm.tiempoTransfusion"
                                            :rules="[
                                                value => !!value || 'Requerido.',
                                                value => value >= 0.01 || 'Valor MENOR al mínimo (0.01)',
                                                value => value <= 10000 || 'Valor MAYOR al máximo (10,000)'
                                            ]"
                                            type="number"
                                            rounded
                                            outlined
                                            label="Tiempo de transfusion (hr)"
                                            dense
                                        ></v-text-field>
                                        <v-checkbox
                                            class="mt-3"
                                            label="Transfusiones previas"
                                            v-model="datosTransfusionForm.transfusionesPrevias"
                                        />
                                        <page-fecha
                                        v-if="datosTransfusionForm.transfusionesPrevias"
                                            label='Fecha de la transfusion'
                                            :model.sync="datosTransfusionForm.fechaUltimaTransfusion"
                                            :dense="true"
                                            :outlined="true"
                                            :rules="[v => !!v || 'Requerido.']"
                                        ></page-fecha>
                                        <v-checkbox
                                            class="mt-3"
                                            label="Urgencia transfusional"
                                            v-model="datosTransfusionForm.urgenciaTransfusional"
                                        />
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="success"
                        :disabled="!validaSol"
                        :loading="loadingSol"
                        @click="agrega"
                    >
                        Agregar
                    </v-btn>
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        </v-dialog>

        <!--
        Dialog Terminar
        -->
        <v-dialog
            v-model="dialogTermina"
            max-width="450"
            persistent
        >
            <v-form
                ref="suspendeForm"
                onSubmit="return false;"
                v-model="validaTerm"
            >
                <v-card>
                    <v-card-title
                        class="headline orange lighten-2"
                        primary-title
                    >
                        Suspende/termina
                    </v-card-title>
                    <v-card-text class="mt-5">

                        <v-text-field
                            v-model="datosTerminaTransfusion.volumenTransfundido"
                            :rules="[
                                value => !!value || 'Requerido.',
                                value => value >= 0.01 || 'Valor MENOR al mínimo (0.01)',
                                value => value <= 10000 || 'Valor MAYOR al máximo (10,000)'
                            ]"
                            type="number"
                            label="Volumen transfundido"
                            rounded
                            outlined
                            dense
                        >
                        </v-text-field>
                        <v-text-field
                            v-model="datosTerminaTransfusion.observaciones"
                            label="Observaciones"
                            rounded
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="success"
                            :disabled="!validaTerm"
                            @click="termina"
                        >
                            Terminar
                        </v-btn>

                        <v-btn color="error" @click="cancelaTermina">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <transf-agrega-signos
        :dialogSignosTodos.sync='dialogSignosTodos'
        :internamiento="internamiento"
        :estaFecha="estaFecha"
        :item="itemSignos"
        />

        <transf-agrega-observacion
        :dialogObservaciones.sync="dialogObservaciones"
        :item="itemSignos"
        :cargaTransfusionesTodas="cargaTransfusionesTodas"
        />
    </v-sheet>
</template>

<script>
export default {
    components:{
        'page-fecha':()=>import('@/components/com/SelectEditaFecha'),
        'transf-agrega-signos':()=>import('@/components/hospital/enfe/transf/dialogs/RegistrarSignosDialog'),
        'transf-agrega-observacion':()=>import('@/components/hospital/enfe/transf/dialogs/NuevaObservacionDialog')
    },
    mounted(){
        this.cargaTransfusionesTodas()
        this.fechaActual = this.estaFecha
    },
    props:{
        estaFecha:String,
        internamiento:Number,
    },
    data:()=>({
        horaInicio: false,

        loadingDatos:false,
        muestraSol:false,
        datosTable:[],
        solActivo:false,
        fechaActual:'',
        //dialogo observaciones
        dialogObservaciones: false,

        //para dialogo de signos
        dialogSignosTodos: false,
        horaCarga: 1,
        itemSignos: {},
        // Para el modal de suspende
        dialogTermina:false,
        validaTerm:false,
        maximoSuspende :1,
        loadingSusp:false,
        agregaBalance:false,

        itemPorTerminar:{},

        ruleFechaObligatoria:[
            value => !!value || "Requiere fecha ultima transfusion",
            value => /^([0-2][0-9]|3[0-1])(-)(0[1-9]|1[0-2])\2(\d{4})$/.test(value) || "Formato debe ser tipo 31-01-2021",
        ],

        // Para el modal
        loadingSol:false,
        loadingSolList:false,
        dialog:false,
        validaSol:false,

        gruposRh: ['A+', 'A-', 'B+', 'B-', 'AB+','AB-','O+','O-'],

        componentesTransfusion: [
            'Concentrados eritrocitarios',
            'Plasma fresco congelado',
            'Crioprecipitados',
            'Plaquetas por aféresis',
            'Plaquetas convencionales',
            'Sangre total'
        ],

        datosTransfusionForm:{
            id: '',
            motivoTransfusion:'',
            identidadPaciente:false,
            grupoRhPaciente: '',
            grupoRhUnidad: '',
            componenteaTransfundir:'',
            volumenaTransfundir: '',
            tiempoTransfusion:'',
            pruebasCompatibilidad: false,
            antecedentesAlergias:false,
            transfusionesPrevias:false,
            fechaUltimaTransfusion:'',
            consentimientoInformado: false,
            indicacionEscrita: false,
            urgenciaTransfusional: false,
            fechaTransfusion:'',
            numeroUnidad: '',
            tipoUnidad: '',
            horaInicio: '',
            medicoIndicante: 'aslgo',
            enfermeraAplicante: 'algo',
            responsableDobleVerificacion: 'algo',
            internamiento: '',
            fechaTermino: ''
        },
        datosTerminaTransfusion:{
            id: '',
            volumenTransfundido: 0.0,
            observaciones: ''
        }


    }),
    methods:{

        abreCancela(itm){
            this.itemPorTerminar = itm
            this.dialogTermina = true
        },
        abreSignosVitales(item){
            this.itemSignos = item
            this.dialogSignosTodos = true
        },
        abreObservacion(item){
            this.itemSignos = item
            this.dialogObservaciones = true
        },
        claseTerminado(itm){
            if (itm.terminada){
                return "gradiente-"+itm.turno
            }
        },
        muestraDatos(){
            //
        },
        //Modificar para la carga de Transfusiones
        async cargaTransfusionesTodas(){
            this.muestraSol = true
            let params = {
                internamiento: this.internamiento,
                fecha: this.estaFecha,
            }
            try{
                let cs = await this.$http({
                    method:'GET',
                    url:'/enfermeria/obtenerTransfusionesPorInternamientoYFecha',
                    params:params
                })
                this.datosTable = cs.data
                this.muestraSol = false
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.muestraSol = false
            }
        },
        //Modificar para agregar Transfusion
        async agrega(){
            this.dialog = false
            try {
                this.datosTransfusionForm.internamiento = this.internamiento
                let res = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaTransfusiones',
                    data:this.datosTransfusionForm
                })
                this.cargaTransfusionesTodas()
                this.abreSignosVitales(await res.data.transfusion)
                this.resetearDatosTransfusion()
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loadingSol = false
            }
        },
        async termina(){
            this.dialog = false
            this.datosTerminaTransfusion.id = this.itemPorTerminar.id
            try {
                this.datosTransfusionForm.internamiento = this.internamiento
                let res = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaTransfusiones',
                    data:this.datosTerminaTransfusion
                })
                this.cargaTransfusionesTodas()
                this.dialogTermina = false
                this.abreSignosVitales(await res.data.transfusion)
                this.resetearDatosTransfusion()
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loadingSol = false
            }
        },
        cancela(){
            this.agregaBalance = false
            this.dialog = false
            this.$refs.solForm.reset()
        },

        cancelaTermina(){
            this.dialogTermina = false
            this.agregaBalance = false
        },

        calculaFecha(inicio, final){

            // Fecha inicial a las 8:00 (del dia)
            let fi = this.moment(inicio+' 08:00', 'DD-MM-YYYY HH:mm').toDate().getTime()

            let fechaFin = final

            // La diferencia (en dias) de las dos fecha ajustado a las 24 horas
            let diferencia = (final - fi) / 86400000

            // La cantidad (en enteros) de días a agregar
            let agregar = parseInt(diferencia)
            //console.log(agregar)
            // Agrega los dias
            fechaFin = fi + (agregar * 86400000)

            // Regresa el STRING de la fecha
            return this.moment(fechaFin).format('DD-MM-YYYY')
        },
        turnoColor(val){
            let text = ''

            switch (val){
                case 'mat':
                    text = 'blue'
                    break
                case 'ves':
                    text = "green"
                    break
                case 'noc':
                    text = 'red'
                    break
            }
            return text
        },
        resetearDatosTransfusion(){
            this.$refs.solForm.reset()
            this.datosTransfusionForm = {
            id: '',
            motivoTransfusion:'',
            identidadPaciente:false,
            grupoRhPaciente: '',
            grupoRhUnidad: '',
            componenteaTransfundir:'',
            volumenaTransfundir: '',
            tiempoTransfusion:'',
            pruebasCompatibilidad: false,
            antecedentesAlergias:false,
            transfusionesPrevias:false,
            fechaUltimaTransfusion:'',
            consentimientoInformado: false,
            indicacionEscrita: false,
            urgenciaTransfusional: false,
            fechaTransfusion:'',
            numeroUnidad: '',
            tipoUnidad: '',
            horaInicio: '',
            medicoIndicante: 'algo',
            enfermeraAplicante: 'algo',
            responsableDobleVerificacion: 'algo',
            internamiento: '',
            fechaTermino: ''
            }
            this.datosTerminaTransfusion = {
                id: '',
                volumenTransfundido: 0.0,
                observaciones: ''
            }
        }
    },
    computed:{


    },
    watch:{
        estaFecha(){
            this.cargaTransfusionesTodas()
        },

    },
}
</script>

<style>
.textoarriba{
    vertical-align: top;

}
.tablaDiag{
    border: 1px solid  #ECECEC;
    border-spacing: 0.5rem;
    border-collapse: collapse;
}
.gradiente-mat{
    background-image: repeating-linear-gradient( 45deg, rgba(24, 255, 255, 0.4), rgba(24, 255, 255, 0.4) 2px, #FFF 0, #FFF 10px);
}
.gradiente-ves{
    background-image: repeating-linear-gradient( 45deg, rgba(0, 255, 0, 0.3), rgba(0, 255, 0, 0.3) 2px, #FFF 0, #FFF 10px);
}
.gradiente-noc{
    background-image: repeating-linear-gradient( 45deg, rgba(255, 23, 68, 0.2), rgba(255, 23, 68, 0.2) 2px, #FFF 0, #FFF 10px);
}
.pre-formatted {
  white-space: pre-wrap;
}
</style>